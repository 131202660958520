@import './../../../scss/colours.module.scss';

.NewTaskModal {
  width: 820px;
  height: 620px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(82, 61, 61, 0.3);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-left: 40px;
  margin-right: 40px;
}

.Close {
  width: 10px;
  height: 10px;
  fill: #6b6b6b;
  cursor: pointer;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 29px;
  color: #888888;
}

.Subtitle {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-left: 40px;
  margin-top: 20px;
}

.Hr {
  border: 1px solid #f9f9f9;
  margin: 0px;
  width: 99.5%;
}

.Container {
  display: flex;
  flex-direction: column;
}

.Tabs {
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  margin-top: 10px;
}

.Tab {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-bottom: 1px solid #d4d4d4;
}

.ActiveTab {
  color: #2e1543;
  border-bottom: 2px solid $main-color;
}

.HR {
  border-bottom: 2px solid #d4d4d4;
}

.Tasks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-left: 40px;
  height: 240px;
}

.Task {
  width: 210px;
  height: 96px;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  margin-right: 32px;
  margin-bottom: 32px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  &:hover {
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
  }
}

.SelelctedTask {
  border: 2px solid $green10;
  margin-top: -1px;
  margin-left: -1px;
  margin-bottom: -1px;
  margin-right: 31px;
}

.SelectedName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TaskTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-top: 8px;
  margin-left: 12px;
  margin-right: 12px;
}

.TaskDescription {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 8px;
}

.ButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}
.SelectButton {
  width: 90%;
  height: 44px;
  background: $green10;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    cursor: default;
    color: #d6d6d6;
    background-color: #f2f2f2;
  }
}
