@import './../../scss/colours.module.scss';
$width: 356px;

.SignUpPage {
  width: 500px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 600px) {
    width: auto;
    box-shadow: none;
  }
}

.Partnership {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.GoCopy {
  font-size: 37px;
  font-weight: 600;
  color: #000000;
  margin-right: 12px;
}

.Appsumo {
  width: 400px;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin-bottom: 8px;
}

.Subtitle {
  font-size: 14px;
  text-align: center;
  color: #787878;
  margin-bottom: 40px;
}

.FormInputContainer {
  margin-bottom: 16px;
}

.AppsumoContainer {
  margin-top: 12px;
}

.FormInput {
  width: 356px;
  height: 44px;
}

.CreateAccountButton {
  width: $width;
  height: 44px;
  background: $green10;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 40px;
}

.TandC {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #000000;
  width: 250px;
  text-align: center;
  margin-top: 8px;
}

.TermsText {
  text-decoration: underline;
  cursor: pointer;
}

.Split {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: $width;
  margin-top: 32px;
}

.ApiError {
  color: $red;
  background-color: transparent;
  font-weight: bold;
  margin-bottom: 32px;
}

.LogIn {
  color: #000000;
  margin-top: 24px;
  font-size: 14px;
}

.LogInText {
  font-weight: bold;
  font-size: 16px;
  color: #787878;
  margin-left: 4px;
  cursor: pointer;
}

.Announcement {
  background-color: red;
  color: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
}
