.Count {
  color: #c7c7c7;
  font-size: 14px;
  margin-right: 16px;
  cursor: pointer;
}

.Countdropdown {
  color: #303030;
  font-size: 14px;
  position: absolute;
  background-color: white;
  padding: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 60px;
}

.CountContainer {
  width: 95px;
  display: flex;
  align-items: center;
}

.Item {
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  height: 20px;
}

.Chevron {
  transform: rotate(180deg);
  width: 10px;
  height: 10px;
  fill: #c7c7c7;
  margin-left: 8px;
}

.OpenChevron {
  transform: rotate(0deg);
}

.Text {
  width: 120px;
}
