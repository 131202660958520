.Count {
  color: #37afa1;
  font-size: 14px;
  cursor: pointer;
  height: 32px;
  width: 90px;
  display: flex;
  align-items: center;
  border: 1px solid #37afa1;
  border-radius: 4px;
}

.CountContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Countdropdown {
  color: #303030;
  font-size: 14px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 180px;
  height: 80px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 8px;
}

.Item {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  height: 32px;
  cursor: pointer;
  color: #303030;
  &:hover {
    background-color: #f5f5f5;
  }
}

.Chevron {
  transform: rotate(180deg);
  width: 10px;
  height: 10px;
  fill: #37afa1;
  margin-left: 8px;
}
