@import './../../../scss/colours.module.scss';

.ReplaceButton {
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #767c83;
  outline: none;
  cursor: pointer;
  background-color: $background-color;
  cursor: pointer;
  margin-left: 16px;
  height: 40px;
  width: 140px;
  &:hover {
    border: 2px solid #f1f1f1;
  }
}

.Error {
  color: $red;
  font-size: 14px;
  margin-bottom: 8px;
}

.FieldBlankError {
  color: $red;
  font-size: 14px;
  margin-left: 8px;
}

.ButtonsContainer {
  display: flex;
  align-items: center;
}
