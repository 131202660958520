@import './../../scss/colours.module.scss';
.EmailConfirmationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Form {
  margin-top: 100px;
  width: 398px;
  text-align: center;
}

.Title {
  font-size: 40px;
}

.SubTitle {
  margin-top: 24px;
  width: 110%;
}

.CodeContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
}

.DigitContainer {
  width: 80px;
  height: 80px;
}

.Input {
  width: 100%;
  height: 100%;
  border: 1px solid #bdbdbd;
  outline: none;
  font-size: 64px;
  text-align: center;
}

.Input:focus {
  border: 1px solid black;
}

.SubmitButton {
  margin-top: 40px;
  width: 100%;
  height: 48px;
  background-color: $green10;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: #37afa1;
  }
  &:disabled {
    cursor: default;
    color: #d6d6d6;
    background-color: #f2f2f2;
  }
}

.CodeError {
  width: 400px;
  height: 46px;
  background-color: $red10;
  border: 1px solid $red40;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.ResendComponent {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Resend {
  margin-left: 3px;
  text-decoration: underline;
  cursor: pointer;
}

.Container {
  position: absolute;
  top: 24px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Suspicious {
  background-color: $red10;
  color: $red;
  width: 600px;
  padding: 8px;
  line-height: 150%;
  border-radius: 6px;
}
