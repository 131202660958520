@import './../../scss/colours.module.scss';

.Halfs {
  display: flex;
}

.Deadline {
  font-size: 24px;
  color: red;
}

.PriceLevels {
  font-weight: 600;
  margin-bottom: 16px;
}

.Description {
  padding-top: 5px;
  padding-bottom: 5px;
}

.Left {
  width: 400px;
}

.Right {
  display: flex;
  flex-direction: column;
}

.ListItem {
  margin-top: 5px;
}

.PriceLevel {
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PurchaseButton {
  height: 40px;
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: $green10;
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  margin-top: 24px;
  &:hover {
    background-color: #37afa1;
  }
  &:disabled {
    cursor: default;
    &:hover {
      background-color: $green10;
    }
  }
}

.PriceCompleted {
  text-decoration: line-through;
}

.Warning {
  width: 575px;
  line-height: 20px;
  color: #354240;
}
