.Header {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #f5f5f5;
  margin-top: 16px;
}

.Title {
  font-size: 18px;
  color: #2a3d3a;
}

.Folders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.FolderIcon {
  width: 24px;
  height: 24px;
  fill: #37afa1;
}

.SectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  margin-bottom: 40px;
}

.LeftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.IconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  width: 40px;
  height: 32px;
  margin-right: 6px;
}

.AllFolders {
  cursor: pointer;
  height: 32px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: #2a3d3a;
  font-weight: bold;
  font-size: 14px;
  &:hover {
    background-color: #f5f5f5;
  }
}

.BreadcrumbContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.BreadcrumbChevron {
  width: 10px;
  height: 10px;
  transform: rotate(90deg);
  fill: #c0c0c0;
  margin-left: 4px;
  margin-right: 4px;
}

.Path {
  color: #2a3d3a;
  margin-left: 8px;
  font-weight: bold;
  font-size: 14px;
}

.ViewFolder {
  font-size: 14px;
  cursor: pointer;
  color: #078dee;
  font-weight: bold;
  width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  &:hover {
    background-color: #edefef;
  }
}

.Icon {
  font-size: 20px;
  color: #37afa1;
  font-weight: bold;
}

.FileTitle {
  color: #2a3d3a;
  font-weight: bold;
  font-size: 14px;
  height: 32px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
}

.Files {
  display: flex;
  flex-direction: column;
}

.NoFiles {
  color: #98acb8;
  font-size: 14px;
}
