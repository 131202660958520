.Sidebar {
  min-width: 100px;
  max-width: 100px;
  min-height: 100vh;
  border-right: 2px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Info {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Top {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.HeaderTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  max-height: 300px;
  height: 100%;
  margin-bottom: 5em;
}

.Logo {
  width: 40px;
  border-radius: 50%;
}

.Tab {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #98acb8;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 42px;
  &:hover {
    font-weight: bold;
    color: #2a3d3a;
    border-right: 2px solid #37afa1;
  }
}

.SelectedTab {
  font-weight: bold;
  color: #2a3d3a;
  border-right: 2px solid #37afa1;
}

.TabIcon {
  width: 20px;
  height: 20px;
  fill: #98acb8;
}

.Tab:hover .TabIcon {
  fill: #37afa1;
}

.TabTitle {
  margin-top: 12px;
  font-size: 10px;
}

.SelectedIcon {
  fill: #37afa1;
}
