.tippy-box[data-theme~='light'] {
  color: #fff;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15),
    0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);
  background: linear-gradient(
      241.39deg,
      #d27cd3 -3.27%,
      rgba(52, 43, 158, 0) 85.64%
    ),
    linear-gradient(88.24deg, #342b9e -13.14%, rgba(210, 124, 211, 0) 128.8%);
  font-weight: 600;
}
.tippy-box[data-theme~='light'][data-placement^='top'] > .tippy-arrow:before {
  display: none;
}
.tippy-box[data-theme~='light'][data-placement^='bottom']
  > .tippy-arrow:before {
  display: none;
}
.tippy-box[data-theme~='light'][data-placement^='left'] > .tippy-arrow:before {
  display: none;
}
.tippy-box[data-theme~='light'][data-placement^='right'] > .tippy-arrow:before {
  display: none;
}
.tippy-box[data-theme~='light'] > .tippy-backdrop {
  background-color: #fff;
}
.tippy-box[data-theme~='light'] > .tippy-svg-arrow {
  display: none;
}
