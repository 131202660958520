.LandingPage {
  overflow-x: hidden;
}

.Navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  position: fixed;
  z-index: 5;
}

.NavbarButtons {
  display: flex;
}

.ActiveNavbar {
  opacity: 90%;
  background: #fcfcfc;
}

.LogIn {
  outline: none;
  border: none;
  background-color: inherit;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #787878;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    color: black;
  }
}

.First {
  height: 100vh;
  background: linear-gradient(
      10.13deg,
      #edfaf9 -10.82%,
      rgba(255, 255, 255, 0) 118.99%
    ),
    linear-gradient(
      231.51deg,
      #f6f1f7 4.51%,
      rgba(255, 255, 255, 0) 111.94%,
      rgba(255, 254, 255, 0.0552304) 111.94%
    ),
    linear-gradient(100.24deg, #ffffff 49.75%, rgba(255, 255, 255, 0) 101.35%);
}

.FirstContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FirstSubContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1100px;
  @media screen and (max-width: 1024px) {
    width: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.HeroContainer {
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 850px) {
    align-items: center;
    text-align: center;
  }
}

.Header {
  font-weight: bold;
  font-size: 44px;
  line-height: 53px;
  text-transform: capitalize;
  margin-bottom: 24px;
  width: 400px;
  @media screen and (max-width: 1024px) {
    font-size: 36px;
    line-height: 40px;
    width: 100%;
  }
  @media screen and (max-width: 850px) {
    font-size: 30px;
    line-height: 37px;
  }
}

.SubHeader {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  width: 420px;
  margin: 0px;
  @media screen and (max-width: 850px) {
    font-size: 19px;
    width: 90%;
  }
}
.ButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  @media screen and (max-width: 850px) {
    margin-top: 20px;
  }
}

.SignUp {
  width: 262px;
  height: 48px;
  background: #2a9d8f;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #37afa1;
  }
}

.NavbarSize {
  height: 34px;
  margin-right: 16px;
  border-radius: 4px;
  @media screen and (max-width: 850px) {
    display: none;
  }
}

.ButtonMain {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}

.ButtonSecondary {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-left: 6px;
}

.NoCC {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-top: 6px;
}

.UseCases {
  width: 500px;
  height: 388px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  @media screen and (max-width: 850px) {
    width: 340px;
    height: 270px;
    margin-top: 24px;
  }
}

.UseCaseHeader {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 850px) {
    height: 24px;
  }
}

.UseCaseButton {
  background: black;
  border-radius: 4px;
  width: 88px;
  height: 24px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: default;
  @media screen and (max-width: 850px) {
    width: 60px;
    height: 16px;
    font-size: 8px;
  }
}

.CursorIcon {
  width: 16px;
  position: relative;
  right: 20px;
  @media screen and (max-width: 850px) {
    width: 10px;
  }
}

.EndCursorPosition {
  right: -50px;
  top: 10px;
}

.Animation {
  animation: usecase 2s;
  -moz-animation: usecase 2s;
}

@keyframes usecase {
  from {
    right: 0px;
  }
  to {
    right: -50px;
    top: 10px;
  }
}

.Hr {
  border: 1px solid #ededed;
  padding: 0px;
  margin: 0px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Text {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  padding: 25px;
  height: 270px;
  @media screen and (max-width: 850px) {
    font-size: 12px;
    line-height: 20px;
    height: 160px;
  }
}

.FadeInText {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
}

.PSA {
  color: #787878;
  font-size: 12px;
  padding-left: 25px;
  @media screen and (max-width: 850px) {
    font-size: 10px;
  }
}

.WritingReimagined {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.SectionHr {
  width: 40px;
  height: 4px;
  background-color: #c4c4c4;
  border: none;
  margin-top: 100px;
  @media screen and (max-width: 850px) {
    margin-top: 64px;
  }
}

.ReimaginedHeader {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  margin-top: 4px;
  margin-bottom: 48px;
  text-transform: capitalize;
  @media screen and (max-width: 850px) {
    font-size: 18px;
    margin-bottom: 24px;
    line-height: 20px;
  }
}

.ReimaginedText {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  width: 360px;
  @media screen and (max-width: 850px) {
    width: 300px;
    font-size: 14px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.Robot {
  @media screen and (max-width: 850px) {
    width: 60%;
    height: 60%;
  }
}

.Feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 140px;
  padding-bottom: 140px;
  background: linear-gradient(
      10.13deg,
      #edfaf9 -10.82%,
      rgba(255, 255, 255, 0) 118.99%
    ),
    linear-gradient(
      231.51deg,
      #f6f1f7 4.51%,
      rgba(255, 255, 255, 0) 111.94%,
      rgba(255, 254, 255, 0.0552304) 111.94%
    ),
    linear-gradient(100.24deg, #ffffff 49.75%, rgba(255, 255, 255, 0) 101.35%);
  @media screen and (max-width: 850px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.FeatureContainer {
  max-width: 1800px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 850px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}

.Left {
  margin-left: 88px;
  margin-right: 108px;
  @media screen and (max-width: 850px) {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.FeatureTitle {
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  color: #000000;
  width: 435px;
  margin-bottom: 36px;
  @media screen and (max-width: 850px) {
    width: 90%;
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.FeatureSubheader {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #979797;
  width: 520px;
  @media screen and (max-width: 850px) {
    width: auto;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.SubheaderIntro {
  font-weight: 600;
  color: #000000;
}

.Right {
  width: 800px;
  height: 600px;
  background: #f7f7f7;
  border-radius: 32px;
  overflow: hidden;
  filter: drop-shadow(0px 15px 11px rgba(0, 0, 0, 0.2));
  @media screen and (max-width: 850px) {
    max-width: 400px;
    width: 90%;
    height: 300px;
    margin-top: 20px;
    border-radius: 16px;
  }
}

.PlaygroundIcon {
  width: 1143px;
  height: 812px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.17));
  position: relative;
  left: 50px;
  @media screen and (max-width: 850px) {
    width: 400px;
    height: 300px;
  }
}

.CTA {
  padding-left: 20px;
  padding-right: 20px;
  height: 48px;
  background: #2a9d8f;
  border-radius: 8px;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-top: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background: #37afa1;
  }
  @media screen and (max-width: 850px) {
    margin-top: 20px;
    font-size: 12px;
  }
}

.ReverseFeature {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 140px;
  padding-bottom: 140px;
  @media screen and (max-width: 850px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.ReverseFeatureContainer {
  max-width: 1800px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 850px) {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
  }
}

.ReverseLeft {
  width: 800px;
  height: 600px;
  background: #f7f7f7;
  border-radius: 32px;
  overflow: hidden;
  filter: drop-shadow(0px 15px 11px rgba(0, 0, 0, 0.2));
  @media screen and (max-width: 850px) {
    max-width: 400px;
    width: 90%;
    height: 300px;
    margin-top: 20px;
    border-radius: 16px;
  }
}

.ReverseRight {
  margin-right: 88px;
  margin-left: 108px;
  @media screen and (max-width: 850px) {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.WritersBlockIcon {
  width: 1143px;
  height: 812px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.17));
  position: relative;
  right: 550px;
  @media screen and (max-width: 850px) {
    width: 600px;
    height: 400px;
    right: 300px;
  }
}

.Key {
  background: linear-gradient(180deg, #35363a 0%, rgba(39, 40, 43, 0.82) 100%);
  box-shadow: 0px 13px 0.6em rgb(0, 0, 0 / 30%), 0px 4px 4px rgb(0, 0, 0 / 35%);
  border-radius: 12px;
  width: 100px;
  height: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 53px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px;
}

.KeyTitle {
  background-image: linear-gradient(
    180deg,
    #edfaf9 0%,
    rgba(0, 0, 0, 0) 531.25%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.Shortcut {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UseCasesList {
  background: #1c1d1f;
  padding-top: 140px;
  padding-bottom: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  @media screen and (max-width: 850px) {
    padding-top: 64px;
    padding-bottom: 64px;
    padding: auto;
  }
}

.Title {
  margin-bottom: 100px;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  color: #ffffff;
  background-image: linear-gradient(
    180deg,
    #edfaf9 0%,
    rgba(0, 0, 0, 0) 531.25%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  @media screen and (max-width: 850px) {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 40px;
  }
}

.CardsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1100px;
  width: 100%;
  @media screen and (max-width: 850px) {
    justify-content: center;
  }
  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.Card {
  width: 200px;
  height: 268px;
  background: linear-gradient(179.23deg, #28292c 0.36%, #27272b 103.11%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 32px;
  padding-top: 32px;
  margin-bottom: 24px;
  @media screen and (max-width: 850px) {
    width: 160px;
    height: 210px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.CardTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 12px;
  margin-bottom: 24px;
  @media screen and (max-width: 850px) {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 6px;
  }
}

.CardItem {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #8a8f98;
  margin-bottom: 16px;
  @media screen and (max-width: 850px) {
    font-size: 14px;
    margin-bottom: 8px;
    line-height: 20px;
  }
}

.Testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
  background: linear-gradient(
      352.91deg,
      rgba(252, 252, 252, 0) 65.45%,
      #d3edeb 97.26%
    ),
    linear-gradient(
      94.1deg,
      #fcfcfc 61.47%,
      #fcfcfc 104.66%,
      rgba(252, 252, 252, 0) 106.89%,
      #fcfcfc 106.89%
    );
  @media screen and (max-width: 850px) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.TestimonialTitle {
  font-weight: bold;
  font-size: 44px;
  line-height: 73px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 40px;
  }
}

.TestimonialCards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1200px;
  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    width: auto;
    justify-content: center;
  }
}

.Pricing {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 140px;
  @media screen and (max-width: 850px) {
    padding-top: 24px;
    padding-bottom: 64px;
  }
}

.PricingTitle {
  font-weight: bold;
  font-size: 44px;
  color: #000000;
  margin-bottom: 4px;
  text-align: center;
  @media screen and (max-width: 850px) {
    font-size: 24px;
  }
}

.PricingSubheaderContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 600px;
  text-align: center;
  @media screen and (max-width: 850px) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.Subheader {
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5;
  color: #000000;
  @media screen and (max-width: 850px) {
    font-size: 16px;
    // width: 80%;
  }
}

.SubheaderContact {
  color: #2a9d8f;
  cursor: pointer;
  margin-left: 4px;
}

.CTASection {
  padding-top: 140px;
  padding-bottom: 140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 850px) {
    padding-top: 24px;
    padding-bottom: 64px;
  }
}

.CTATitle {
  font-weight: bold;
  font-size: 44px;
  line-height: 39px;
  color: #000000;
  @media screen and (max-width: 850px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.CTASubheader {
  font-size: 20px;
  text-align: center;
  color: #222222;
  margin-bottom: 24px;
  margin-top: 28px;
  width: 700px;
  @media screen and (max-width: 850px) {
    width: auto;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.CheckMark {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  height: 24px;
  @media screen and (max-width: 850px) {
    height: 16px;
  }
}

.CheckmarkContainer {
  margin-top: 24px;
  @media screen and (max-width: 850px) {
    margin-top: 16px;
  }
}

.CheckMarkDescription {
  margin-left: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.CheckIcon {
  width: 16px;
  height: 16px;
  fill: #2a9d8f;
  @media screen and (max-width: 850px) {
    width: 14px;
    width: 14px;
  }
}

.Arrow {
  width: 16px;
  height: 16px;
  fill: #ffffff;
  margin-left: 6px;
  @media screen and (max-width: 850px) {
    width: 14px;
    width: 14px;
  }
}
