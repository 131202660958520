@import './../../../scss/colours.module.scss';

.StatsCounter {
  border-radius: 4px;
  font-size: 14px;
  height: 24px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #342b9e;
  margin-right: 16px;
  background-color: #cecbf1;
}

.Expired {
  color: $red;
  background-color: $red40;
}

.Number {
  padding: 4px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #dfddf7;
  margin-left: 3px;
  margin-right: 3px;
  &:hover {
    background-color: #d4d1f0;
  }
}

.NumberExpired {
  background-color: $red40;
  &:hover {
    background-color: $red10;
  }
}
