.TestimonialCard {
  width: 362px;
  height: 200px;
  border-radius: 8px;
  border: 1px solid #e7e9f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 32px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 24px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.Testimonial {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  @media screen and (max-width: 850px) {
    width: 90%;
    height: 400px;
    right: 300px;
  }
}

.AuthorData {
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
}

.AuthorInfo {
  display: flex;
  flex-direction: column;
}

.Avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 12px;
}

.AuthorName {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 4px;
}

.AuthorJob {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
