.Text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147.2%;
  color: #000000;
  white-space: pre-wrap;
}

.Ad {
  color: #000000;
  display: flex;
  flex-direction: column;
}

.FacebookAd {
  color: #000000;
  display: flex;
  flex-direction: column;
}

.FacebookHeadline {
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: bold;
}

.Image {
  width: 100%;
  height: 100px;
  background: radial-gradient(
    44.87% 44% at 51.16% 43%,
    #2e1543 6.77%,
    rgba(196, 196, 196, 0) 100%
  );
  margin-top: 12px;
}

.AvatarContainer {
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.InfoContainer {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.Circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: linear-gradient(
    267.08deg,
    #2e1543 -3.42%,
    rgba(196, 196, 196, 0) 163.71%
  );
}

.SponsoredContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(101, 103, 107);
  font-size: 12px;
}

.Sponsored {
  margin-right: 4px;
}

.Globe {
  height: 10px;
  margin-left: 2px;
}

.Name {
  font-weight: bold;
  font-size: 14px;
}

.GoogleAd {
  font-weight: bold;
  font-size: 14px;
  line-height: 147.2%;
  margin-bottom: 6px;
}

.GoogleHeadline {
  font-weight: 500;
  font-size: 18px;
  line-height: 147.2%;
  color: #1a0eab;
  margin-bottom: 8px;
}
