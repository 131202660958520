@import './../../scss/colours.module.scss';

.Footer {
  background-color: #e8e8e8;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 500px;
  @media screen and (max-width: 850px) {
    height: auto;
  }
}

.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 800px;
  padding-left: 75px;
  padding-right: 75px;
  @media screen and (max-width: 850px) {
    width: 80%;
  }
  @media screen and (max-width: 600px) {
    width: auto;
    flex-wrap: wrap;
  }
}

.Column {
  @media screen and (max-width: 600px) {
    width: 100px;
  }
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #000000;
  margin-top: 75px;
  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin-top: 40px;
  }
}

.Items {
  display: flex;
  flex-direction: column;
  margin-top: 26px;
}

.Item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #8a8f98;
  margin-bottom: 33px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
