.ResultCard {
  width: 430px;
  padding: 24px;
  padding-bottom: 16px;
  background: white;
  border-radius: 8px;
  margin-bottom: 24px;
  border: 1px solid #d4d4d4;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}

.Playground {
  width: 400px;
  border: none;
  box-shadow: none;
  padding: 0px;
  border: none;
  margin-bottom: 8px;
}

.Text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 147.2%;
  color: #000000;
}

.HR {
  border-top: 1px solid #c5c5c5;
  margin-top: 20px;
}

.Buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.Button {
  width: 60px;
  height: 40px;
  border-radius: 4px;
  &:hover {
    background-color: #eeeeee;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}

.Icon {
  width: 20px;
  fill: #c7c7c7;
}

.Liked {
  color: #d00000;
  font-weight: bold;
}

.Hr {
  border: 1px solid #f1f1f1;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01);
  transform: rotate(90deg);
  width: 35px;
  margin: 0px;
}

.ArrowIcon {
  transform: rotate(180deg);
}
