.DesktopDashboard {
  display: flex;
  flex-direction: row;
}

.Main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Wrapper {
  min-width: 600px;
  max-width: 800px;
  width: 100%;
  height: 100%;
}

.Overlay {
  background-color: rgba(32, 16, 16, 0.6);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
