.Page {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.Title {
  color: #000;
  font-size: 32px;
}

.GoBack {
  cursor: pointer;
  margin-top: 24px;
  text-decoration: underline;
}
