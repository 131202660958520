$background-color: #fcfcfc;
$main-color: #2f4858;
$secondary-color: #6281f4;
$orange: #f4a261;
$yellow: #ffd764;
$red: #eb5757;
$red100: #d93f1c;
$red40: #f8d2c9;
$red10: #ffefee;
$green10: #2a9d8f;
