@import './../../scss/colours.module.scss';

.Title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
}

.TextArea {
  width: 92%;
  height: 100px;
  border: none;
  outline: none;
  line-height: 20px;
  font-size: 14px;
  color: black;
  background-color: #f3f3f4;
  border-radius: 8px;
  resize: none;
  overflow: hidden;
  font-family: Inter, sans-serif;
  padding: 15px;
  overflow-y: scroll;
}

::placeholder {
  color: #888888;
}

.MaxToken {
  color: #8a8a8a;
  width: 99.5%;
  text-align: end;
  font-size: 14px;
}
