@import './../../../scss/colours.module.scss';

.TextArea {
  width: 100%;
  margin-bottom: -16px;
}

.FormInput {
  width: 99.5%;
  height: 40px;
}
