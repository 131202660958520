@import './../../scss/colours.module.scss';

.NewPassword {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin-bottom: 56px;
}

.Subheader {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-top: 32px;
}

.FormInput {
  width: 356px;
  height: 44px;
}

.Button {
  width: 363px;
  height: 48px;
  background: $green10;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 24px;
}

.Container {
  margin-top: 24px;
  margin-bottom: 24px;
}

.ApiError {
  background-color: $red;
  border-radius: 8px;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 24px;
  margin-top: 24px;
}

.ResetApiError {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin-bottom: 56px;
}

.ResendButton {
  width: 363px;
  height: 48px;
  background: $green10;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
}
