@import './../../scss/colours.module.scss';

.Tippy {
  background-color: tomato;
  color: yellow;
  &[x-placement^='bottom'] {
    .tippy-arrow {
      border-bottom-color: purple;
    }
  }
}

::selection {
  background-color: #dfdafc;
}

.Playground {
  height: 100vh;
  background-color: white;
  overflow: scroll;
}

.LoadingContainer {
  height: 94%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoadingTitle {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.Navbar {
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavbarLeft {
  display: flex;
  align-items: center;
}

.BackArrowContainer {
  min-width: 64px;
  max-width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Hidden {
  position: absolute;
  visibility: hidden;
}

.TasknameInput {
  border: none;
  outline: none;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  padding: 0px 5px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
  &:hover {
    background-color: #f1f1f7;
  }
  &:focus {
    background-color: #f1f1f7;
  }
}

.Expired {
  color: $red;
  background-color: $red40;
  &:hover {
    background-color: $red10;
  }
}

.Halfs {
  display: flex;
  height: 100vh;
}

.Left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  height: 100%;
}

.Tools {
  min-width: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid #f1f1f1;
  border-left: 2px solid #f1f1f1;
}

.Tool {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 48px;
  border-right: 2px solid transparent;
  transition: 0.2s;
  &:hover {
    border-right: 2px solid black;
  }
}

.ActiveTool {
  border-right: 2px solid black;
}

.ToolIcon {
  width: 20px;
  height: 20px;
  fill: #c7c7c7;
  margin-right: -2px;
  transition: 0.2s;
  outline: none;
}

.Tool:hover .ToolIcon {
  margin-right: -2px;
  fill: black;
}

.ActiveIcon {
  fill: black;
  margin-right: -2px;
}

.Right {
  min-width: 440px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  @media screen and (max-width: 1440px) and (max-height: 800px) {
    height: 100%;
  }
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 880px;
  width: 90%;
  min-width: 700px;
  height: 100%;
}

.SaveStateContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 24px;
}

.TextContainer {
  width: 100%;
  height: 100%;
  background-color: white;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TextArea {
  font-family: Inter, sans-serif;
  line-height: 30px;
  font-size: 16px;
  height: 70vh;
  border: none;
  resize: none;
  outline: none;
  width: 95%;
  overflow-x: hidden;
  overflow-wrap: break-word;
  @media screen and (max-width: 1450px) and (max-height: 1000px) {
    height: 65vh;
  }
}

.EmptyContainer {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SelectTaskButton {
  outline: none;
  border: none;
  width: 230px;
  height: 40px;
  background: $green10;
  border-radius: 6px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: #37afa1;
  }
}

.TaskContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 16px;
}

.FormContainer {
  display: flex;
  flex-direction: column;
}

.FormHeaderContainer {
  display: flex;
  justify-content: space-between;
}

.ReplaceIcon {
  height: auto;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.AddIcon {
  outline: none;
  margin-bottom: 4px;
}

.UseCase {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #c7c7c7;
  margin-bottom: 4px;
}

.TaskTitle {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.UseCaseForm {
  margin-top: 22px;
}

.HrContainer {
  margin-bottom: 21px;
  height: 10px;
}

.Hr {
  border: 1px solid #f1f1f1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03);
  width: 100%;
  z-index: 0;
  position: relative;
}

.ChevronContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 0;
  position: relative;
  top: -20px;
}

.Chevron {
  margin-right: 20px;
  cursor: pointer;
  outline: none;
}

.UpsideDownChevron {
  transform: rotate(180deg);
}

.Generations {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 4;
  overflow: hidden;
}

.GenerationsTitleContainer {
  width: 100%;
  margin-bottom: 24px;
}

.GenerationsTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #c7c7c7;
  margin-left: 20px;
}

.Separator {
  width: 400px;
  margin-bottom: 16px;
  border: 1px solid #f5f5f5;
}

.Ideas {
  overflow-y: scroll;
  height: 100%;
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NoResults {
  color: #d4d4d4;
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
}

.NoResultsTitle {
  margin-top: -50%;
}

.Loading {
  color: black;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
}
.Spinner {
  margin-bottom: 24px;
}

.PSA {
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
}

.Overlay {
  background-color: rgba(32, 16, 16, 0.6);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.LikedTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #c7c7c7;
  margin-top: 12px;
  width: 400px;
  text-align: left;
  margin-bottom: 24px;
}

.LikedSamples {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.ExportContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.Export {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 400px;
}

.Dropdown {
  line-height: 20px;
  font-size: 14px;
  color: #999999;
  background-color: white;
  border-radius: 8px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 16px;
}

.DropDownOptions {
  width: 180px;
  background-color: white;
  border: 1px solid #d0d0d0;
  font-size: 14px;
  color: #999999;
  border-radius: 8px;
  margin-top: 4px;
  position: absolute;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.Option {
  height: 24px;
  width: 160px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding-left: 8px;
  cursor: pointer;
  &:hover {
    background-color: #e4e4e4;
    color: #000000;
  }
  margin-top: 4px;
  margin-bottom: 4px;
}

.Selected {
  background-color: #e4e4e4;
  color: #000000;
}

.ProjectName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Chevron {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.OpenChevron {
  transform: rotate(180deg);
  margin-right: 6px;
  width: 20px;
  height: 20px;
}

.ExportButton {
  color: black;
  background-color: transparent;
  width: 100px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  border: 1px solid black;
  cursor: pointer;
  &:visited {
    color: black;
  }
}

.PlagiarismContainer {
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  overflow: scroll;
}

.LoadingPlagiarism {
  width: 100%;
  margin-top: 60px;
}

.LoadingText {
  text-align: center;
  margin-bottom: 16px;
}

.PlagiarismButton {
  width: 196px;
  min-height: 36px;
  max-height: 36px;
  background: #2a9d8f;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  &:hover {
    background: #37afa1;
  }
}

.Divider {
  width: 100%;
  border: 2px solid #f1f1f1;
  border-top: #f1f1f1;
  margin-top: 24px;
}

.ReportContainer {
  width: 100%;
  margin-top: 20px;
}

.NumberContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.PlagiarismNumber {
  font-style: normal;
  font-weight: 500;
  font-size: 54px;
}

.SimilarityTitle {
  font-weight: bold;
  font-size: 14px;
  color: black;
  text-transform: uppercase;
  margin-top: 10px;
}

.GreenZone {
  color: #10bb74;
}

.FineZone {
  color: #a8a8a8;
}

.WarningZone {
  color: orange;
}

.DangerZone {
  color: rgb(238, 36, 36);
}

.ListOfPlagiarisedText {
  margin-top: 40px;
}

.Detail {
  width: 400px;
}

.QuerySection {
  font-size: 16px;
  margin-bottom: 8px;
}

.Url {
  color: #a8a8a8;
  font-size: 16px;
}

.DetailSeparator {
  border: 1px solid #f1f1f1;
  border-top: #f1f1f1;
  margin-top: 24px;
  margin-bottom: 24px;
}

.EditorTools {
  display: flex;
  // margin-right: 10px;
  align-items: center;
}

.CountdropdownContainer {
  margin-left: 40px;
}

.HeaderSeparator {
  margin: 0.375rem;
  width: 1px;
  height: 1rem;
  background-color: #e7e9f5;
}

.InlineToolbar {
  position: absolute;
  background-color: white;
  z-index: 5;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 0px 10px;
  display: flex;
}

.InlineTool {
  &:hover {
    background-color: #f0f0f0;
  }
  cursor: pointer;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
}

.DisabledInlineTool {
  cursor: default;
  &:hover {
    background-color: white;
  }
}

.EditorTool {
  &:hover {
    background-color: #eeeeee;
  }
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  color: #6d758d;
  border-radius: 4px;
}

.SubtitleInlineTool {
  &:hover {
    background-color: #f0f0f0;
  }
  cursor: pointer;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2px;
  width: 34px;
}

.SubTitleLengthContainer {
  position: relative;
  left: 21px;
  top: -5px;
  font-size: 12px;
}

.InlineIcon {
  width: 14px;
  height: 14px;
  fill: black;
}

.EditorToolIcon {
  width: 14px;
  height: 14px;
  fill: #6d758d;
  border-radius: 4px;
}

.ActiveInlineIcon {
  fill: #342b9e;
  width: 14px;
  height: 14px;
}

.BigInlineIcon {
  fill: black;
  width: 16px;
  height: 16px;
}

.BigActiveInlineIcon {
  width: 16px;
  height: 16px;
  fill: #342b9e;
}

.HighlightedLength {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: default;
  width: 34px;
}

.Green {
  color: #10bb74;
}

.Red {
  color: red;
}

.InlineIdeas {
  position: absolute;
  background-color: white;
  z-index: 5;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  width: 394px;
  max-height: 300px;
  overflow-y: scroll;
}

.InlineIdeasError {
  position: absolute;
  background-color: white;
  z-index: 5;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  padding: 8px;
  width: 400px;
  line-height: 125%;
  overflow-y: scroll;
  background-color: $red40;
  color: $red;
  font-size: 14px;
}

.InlineIdea {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    background-color: #f1efff;
  }
}

.InlineIdeaSeparator {
  border: 1px solid #f1f1f1;
  border-top: #f1f1f1;
  padding: 0;
  margin: 0px;
}

.InlineLoading {
  width: 394px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Header {
  font-size: 16px;
}

.HeaderNumber {
  font-size: 12px;
}

.ActiveInlineTool {
  background-color: #f1efff;
}

.History {
  max-height: 100%;
  overflow: auto;
}

.HistoryTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #c7c7c7;
  margin-top: 12px;
  width: 400px;
  text-align: left;
  margin-bottom: 24px;
  margin-left: 20px;
}

.HistoryItems {
  height: 100%;
}

.HistoryItem {
  margin-bottom: 24px;
}

.ItemHeader {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 24px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}

.ActiveInlineIdea {
  background-color: #f1efff;
}

.WriteForMe {
  padding: 12px 20px;
}

.WriteForMeTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #c7c7c7;
  width: 400px;
  text-align: left;
  margin-bottom: 12px;
}

.WriteForMeSubTitle {
  // font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #c7c7c7;
  width: 400px;
  text-align: left;
  margin-bottom: 24px;
}

.Type {
  width: 120px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 4px;
  font-size: 14px;
  color: #888888;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background-color: #e7e7e7;
  }
}

.Shortcut {
  color: #888888;
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
}

.WriteForMeSubtitle {
  margin-bottom: 8px;
  margin-top: 16px;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.OutputButtonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Selected {
  background-color: $green10;
  color: white;
  &:hover {
    background-color: #37afa1;
  }
}

.WriteButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.WriteButton {
  background-color: $green10;
  color: white;
  outline: none;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  height: 36px;
  width: 240px;
  &:hover {
    background-color: #37afa1;
  }
  cursor: pointer;
  &:disabled {
    cursor: default;
    &:hover {
      background-color: $green10;
    }
  }
}

.EditorWriteForMeMessage {
  position: absolute;
  top: 60px;
  color: #342b9e;
  background-color: #cecbf1;
  border-radius: 4px;
  height: 32px;
  width: 320px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ShakespeareRunsLeft {
  display: flex;
}

.WriteForMeRunsLeft {
  width: 100px;
  height: 80px;
  border-radius: 4px;
  color: #888888;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.RunsLeftContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.RunsLeftText {
  font-size: 14px;
  color: #342b9e;
}

.VersionHistory {
  padding: 12px 20px;
  overflow-y: auto;
}

.EditorSettings {
  padding: 12px 20px;
}

.EditorSettingsSubSection {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  margin-bottom: 12px;
  margin-top: 12px;
}

.CounterComponent {
  display: flex;
  align-items: center;
}

.CounterButton {
  width: 30px;
  height: 30px;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.N {
  width: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.Switch {
  width: 175px;
  height: 32px;
  background: #e7e9f5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 34px;
  @media screen and (max-width: 450px) {
    margin-bottom: 0px;
  }
}

.SwitchItem {
  width: 79px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  color: #8a8f98;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: inherit;
  justify-content: center;
}

.ActiveInlineButton {
  background: #929ddd;
  color: #ffffff;
}

.SaveEditorSettingsButton {
  border: none;
  outline: none;
  width: 100%;
  background-color: $green10;
  height: 44px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  &:disabled {
    background-color: #e0e0e0;
    &:hover {
      cursor: default;
      background-color: #e0e0e0;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: #37afa1;
  }
}

.WriteForMeButton {
  background-color: $green10;
  height: 40px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  border: none;
  outline: none;
  color: #ffffff;
  width: 110px;
  cursor: pointer;
  &:disabled {
    cursor: default;
    background-color: #37afa1;
  }
  &:hover {
    background-color: #37afa1;
  }
}

.Buttons {
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: space-between;
}

.UndoIcon {
  width: 18px;
  height: 18px;
  fill: $green10;
  cursor: pointer;
}

.RedoIcon {
  width: 20px;
  height: 20px;
  fill: $green10;
  cursor: pointer;
}

.Button {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 70px;
}

.FormInput {
  width: 100%;
}

.InstructButton {
  font-style: normal;
  font-weight: 600;
  border: none;
  outline: none;
  color: #ffffff;
  background-color: #929ddd;
  width: 110px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    cursor: default;
    background-color: #838ecc;
  }
  &:hover {
    background-color: #838ecc;
  }
}

.ContextualInstructContainer {
  margin-top: 24px;
}
