@import '../../scss/typography.module.scss';
@import 'src/scss/colours.module.scss';

.FormInput {
  border: none;
  outline: none;
  line-height: 20px;
  font-size: 14px;
  color: black;
  height: 45px;
  text-indent: 15px;
  background-color: #f3f3f3;
  border-radius: 6px;
  padding: 0px;
}

::placeholder {
  color: #888888;
  font-size: 14px;
}

.InputError {
  border: 2px solid $red;
}

.ErrorMessage {
  margin-left: 18px;
}

.Error {
  color: $red;
  font-size: 12px;
  line-height: 15px;
}

.Title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 8px;
}

.PasswordMessage {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-top: 2px;
  margin-left: 5px;
}
