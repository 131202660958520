.ToneContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ToneTitle {
  font-size: 14px;
  margin-bottom: 8px;
}

.Dropdown {
  line-height: 20px;
  font-size: 14px;
  color: #999999;
  background-color: white;
  border-radius: 8px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 8px;
}

.DropDownOptions {
  width: 180px;
  background-color: white;
  border: 1px solid #d0d0d0;
  font-size: 14px;
  color: #999999;
  border-radius: 8px;
  position: absolute;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.Option {
  height: 24px;
  width: 160px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding-left: 8px;
  cursor: pointer;
  &:hover {
    background-color: #e4e4e4;
    color: #000000;
  }
  margin-top: 4px;
  margin-bottom: 4px;
}

.Selected {
  background-color: #e4e4e4;
  color: #000000;
}

.ProjectName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Chevron {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.OpenChevron {
  transform: rotate(180deg);
  margin-right: 6px;
  width: 20px;
  height: 20px;
}

.TextArea {
  width: 100%;
  margin-top: 16px;
  margin-bottom: -8px;
}

.Area {
  height: 150px;
}

.Error {
  color: #eb5757;
  text-align: center;
  margin-bottom: 24px;
}
