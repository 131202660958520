@import './../../scss/colours.module.scss';

.Billing {
  width: 760px;
}

.Currentplan {
  color: #000000;
  display: flex;
}

.HR {
  border: 1px solid #f1f1f1;
  margin-top: 24px;
  margin-bottom: 24px;
}

.Subheader {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 16px;
}

.Text {
  margin-left: 2px;
  margin-right: 2px;
}

.ExpiryContainer {
  margin-top: 8px;
  color: #000000;
  display: flex;
}

.Date {
  margin-left: 2px;
  font-weight: bold;
}

.Plan {
  font-weight: bold;
  margin-left: 2px;
  margin-right: 2px;
}

.Manage {
  outline: none;
  border: none;
  text-decoration: underline;
  background: none;
  font-size: 16px;
  margin-top: 16px;
  margin-left: 3px;
  cursor: pointer;
  padding: 0px;
}

.Prices {
  display: flex;
}

.Button {
  background: linear-gradient(
    90deg,
    #2a9d8f 59.45%,
    rgba(42, 157, 143, 0) 202.09%
  );
  border-radius: 4px;
  width: 160px;
  height: 36px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 24px;
  &:hover {
    background: #37afa1;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.UnlimitedSection {
  margin-top: 40px;
}

.Card {
  width: 200px;
}

.Deal {
  color: red;
}
