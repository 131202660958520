.UpgradeModal {
  width: 715px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(82, 61, 61, 0.3);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 790px;
}

.Title {
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 16px;
}

.Description {
  margin-bottom: 24px;
}

.Pricing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 175px;
  padding-left: 17.5px;
  padding-right: 17.5px;
  width: 100%;
  height: 270px;
  @media screen and (max-width: 450px) {
    max-width: 300px;
    height: 100%;
    padding-bottom: 20px;
  }
}

.MobileHeader {
  height: 40px;
  align-items: center;
  padding-bottom: 0px;
  margin-top: 20px;
}

.ShowCard {
  background: #f5f5f5;
  border-radius: 8px;
}

.CardHead {
  height: 145px;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.MobileHead {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.HeaderTitleContainer {
  margin-top: 38px;
  margin-bottom: 10px;
  @media screen and (max-width: 450px) {
    margin: 0px;
  }
}

.BillingCycle {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #8a8f98;
  @media screen and (max-width: 450px) {
    margin-right: 16px;
  }
}

.Switch {
  width: 175px;
  height: 32px;
  background: #e7e9f5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 34px;
  @media screen and (max-width: 450px) {
    margin-bottom: 0px;
  }
}

.SwitchItem {
  width: 79px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  color: #8a8f98;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Active {
  background: #929ddd;
  color: #ffffff;
}

.Cycle {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}

.SectionTitle {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.MobileTitle {
  display: none;
}

.CardBody {
  margin-top: 18px;
  width: 100%;
}

.BodyItem {
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e7e9f5;
  font-size: 13px;
  line-height: 16px;
  color: #8a8f98;
}

.ActiveBodyItem {
  color: #35363a;
}

.BodySectionTitle {
  @extend .SectionTitle;
  margin-top: 44px;
}

.TierHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}

.PricingTier {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.Discount {
  background: #929ddd;
  border-radius: 16px;
  width: 54px;
  height: 22px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PriceContainer {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.Price {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.Duration {
  font-size: 12px;
  line-height: 15px;
  color: #8a8f98;
  margin-left: 16px;
}

.Button {
  background: linear-gradient(
    90deg,
    #2a9d8f 59.45%,
    rgba(42, 157, 143, 0) 202.09%
  );
  border-radius: 4px;
  width: 160px;
  height: 36px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 24px;
  &:hover {
    background: #37afa1;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.CheckIcon {
  width: 12px;
  height: 12px;
  fill: #8a8f98;
  margin-right: 8px;
}

.ActiveCheck {
  fill: #2a9d8f;
}

.SubActive {
  fill: #ffffff;
}

.NotSure {
  margin-top: 24px;
  margin-bottom: 4px;
  font-size: 14px;
}

.TestimonialHeader {
  font-size: 20px;
  font-weight: 600;
}

.Testimonials {
  display: flex;
}

.Testimonial {
  border: none;
  height: 160px;
}

.StillNotSure {
  margin-bottom: 8px;
  font-size: 14px;
}

.GetInTouch {
  height: 40px;
}

.Link {
  color: #000000;
}

.SignOutButton {
  border: none;
  padding: 10px;
  width: 480px;
  margin-bottom: 18px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: #e7e9f5;
  }
}

.Upsell {
  color: #eb5757;
  font-weight: bold;
  width: 400px;
  text-align: center;
  line-height: 24px;
}

.SaveButton {
  outline: none;
  border: none;
  width: 480px;
  height: 40px;
  border-radius: 6px;
  color: #eb5757;
  background-color: #f8d2c9;
  font-size: 14px;
  cursor: pointer;
  margin-top: 16px;
  &:hover {
    background-color: #ffd9d2;
  }
}
