@import './../../../scss/colours.module.scss';

.Modal {
  width: 500px;
  height: 366px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(82, 61, 61, 0.3);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-left: 25px;
  margin-right: 25px;
}

.Close {
  width: 10px;
  height: 10px;
  fill: #6b6b6b;
  cursor: pointer;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 29px;
  color: #888888;
}

.Subtitle {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-left: 25px;
  margin-top: 20px;
}

.Hr {
  border: 1px solid #f9f9f9;
  margin: 0px;
  width: 99.5%;
}

.Container {
  display: flex;
  flex-direction: column;
}

.Projectsubtitle {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.ProjectContainer {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
}

.Dropdown {
  border: 1px solid #d0d0d0;
  line-height: 20px;
  font-size: 16px;
  color: #999999;
  height: 40px;
  text-indent: 15px;
  background-color: white;
  border-radius: 8px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.DropDownOptions {
  width: 250px;
  background-color: white;
  border: 1px solid #d0d0d0;
  font-size: 16px;
  color: #999999;
  border-radius: 8px;
  margin-top: 10px;
  position: absolute;
  max-height: 200px;
  overflow-y: scroll;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.OpenDropDown {
  border: 1px solid $main-color;
}

.Option {
  height: 32px;
  width: 210px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding-left: 8px;
  cursor: pointer;
  &:hover {
    background-color: #e4e4e4;
    color: #000000;
  }
  margin-top: 4px;
  margin-bottom: 4px;
}

.Selected {
  background-color: #e4e4e4;
  color: #000000;
}

.ProjectName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Chevron {
  margin-right: 10px;
}

.OpenChevron {
  margin-right: 10px;
  transform: rotate(180deg);
}

.ButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}
.SelectButton {
  width: 90%;
  height: 44px;
  background: $green10;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    cursor: default;
    color: #d6d6d6;
    background-color: #f2f2f2;
  }
}

.FormInput {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 450px;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 25px;
}
