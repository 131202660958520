@import './../../scss/colours.module.scss';

.Input {
  width: 100%;
}

.Title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-bottom: 6px;
}

.InputContainer {
  width: 91.5%;
  min-height: 30px;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-flow: wrap;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #ffffff;
}

.InputField {
  background-color: inherit;
  height: 26px;
  border: none;
  outline: none;
  line-height: 20px;
  font-size: 16px;
  color: black;
  min-width: 120px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.Item {
  height: 26px;
  background: $red40;
  border-radius: 4px;
  font-style: normal;
  line-height: 20px;
  font-size: 16px;
  color: $red100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 8px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.Close {
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    stroke: #990707;
  }
}

.AddButton {
  border: 1px solid #d4d4d4;
  background-color: white;
  min-height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  line-height: 20px;
  font-size: 16px;
  color: $red100;
  cursor: pointer;
  margin-top: 8px;
  position: absolute;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  padding-top: 5px;
  padding-bottom: 5px;
}

.AddContainer {
  background-color: $red40;
  width: 100%;
  max-width: 395px;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.InputContainer:focus-within {
  border: 1px solid black;
}
