.LogoContainer {
  display: flex;
  margin-left: 20px;
  cursor: pointer;
}

.Icon {
  width: 25px;
  height: 25px;
}

.Logo {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-left: 8px;
}
