.Message {
  position: absolute;
  width: 400px;
  height: 200px;
  color: black;
  background-color: #caf1ec;
  border-radius: 6px;
  z-index: 10;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.ProgressBar {
  width: 100px;
  height: 16px;
  border: 1px solid #342b9e;
  border-radius: 20px;
  display: flex;
  margin-right: 24px;
  overflow: hidden;
}

.Stage {
  width: 13.5px;
  height: 100%;
  border-right: 1px solid #352b9e5d;
}

.ActiveStage {
  background-color: #342b9e;
}

.Cancel {
  font-size: 14px;
  color: red;
  margin-right: 24px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.CloseContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.Right {
  display: flex;
  align-items: center;
}

.CloseIcon {
  width: 10px;
  height: 10px;
}

.Title {
  font-weight: 600;
  text-transform: capitalize;
  margin-left: 12px;
  margin-bottom: 10px;
  font-size: 18px;
}

.Info {
  margin-left: 12px;
  margin-right: 12px;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;
  white-space: pre-wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ContentContainer {
  display: flex;
  height: 100%;
}

.LastMessage {
  position: absolute;
  width: 800px;
  height: 400px;
  color: black;
  background-color: #ffffff;
  border-radius: 8px;
  z-index: 10;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.14);
}

.Left {
  background-color: #caf1ec;
  border-radius: 8px 0px 0px 8px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 360px;
}

.Link {
  margin-left: 4px;
  color: black;
  &:active {
    color: black;
  }
}

.CarouselContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
}

.UsecaseCloseContainer {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 20px;
}

.ItemContainer {
  padding: 20px;
}

.UsecaseCloseIcon {
  width: 10px;
  height: 10px;
}

.Cases {
  margin-top: 4px;
  font-weight: 600;
  font-size: 18px;
  color: #2a9d8f;
}

.UsecaseTitle {
  margin-top: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.Dots {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.Dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-left: 4px;
  margin-left: 4px;
  background-color: rgb(211, 211, 211);
  cursor: pointer;
}

.ActiveDot {
  background-color: #2a9d8f;
}

.Card {
  margin-bottom: 0px;
  height: 210px;
}

.ChevronSize {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.Chevron {
  @extend .ChevronSize;
  transform: rotate(270deg);
  margin-right: 40px;
}

.ChevronRight {
  @extend .ChevronSize;
  transform: rotate(90deg);
  margin-left: 40px;
}
