.VersionHistoryItem {
  border-bottom: 1px solid #c7c7c7;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
}

.Header {
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
}

.HistoryDate {
  margin-left: 16px;
}

.Items {
  margin-top: 24px;
}

.Chevron {
  cursor: pointer;
}

.UpsideChevron {
  transform: rotate(180deg);
}

.Item {
  display: flex;
  padding-left: 40px;
  padding-right: 16px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #ececec;
  }
}

.SelectedItem {
  background-color: #cecbf1;
  &:hover {
    background-color: #cecbf1;
  }
}

.Restore {
  cursor: pointer;
  &:hover {
    font-weight: 600;
  }
}
