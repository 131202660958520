@import './../../../scss/colours.module.scss';

.HelpModal {
  padding-bottom: 12px;
  width: 500px;
  background-color: white;
  border-radius: 6px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 420px;
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
}

.Close {
  width: 10px;
  height: 10px;
  fill: #000000;
  cursor: pointer;
}

.Hr {
  border: 1px solid #f9f9f9;
  margin: 0px;
  width: 99.5%;
}
.Textarea {
  width: 420px;
  margin-top: 10px;
  height: 120px;
  border: none;
  outline: none;
  line-height: 20px;
  font-size: 14px;
  color: black;
  background-color: #f3f3f3;
  border-radius: 4px;
  padding: 15px;
  resize: none;
  overflow: hidden;
  font-family: Inter, sans-serif;
}

::placeholder {
  color: #888888;
}

.Question {
  width: 85%;
  margin-top: 22px;
  font-size: 14px;
  color: #888888;
}

.TypesContainer {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 22px;
  justify-content: space-between;
  width: 90%;
}

.Type {
  width: 140px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 4px;
  font-size: 14px;
  color: #888888;
  cursor: pointer;
  &:hover {
    background-color: #e7e7e7;
  }
}

.Button {
  border: none;
  outline: none;
  width: 90.5%;
  background-color: $green10;
  height: 44px;
  border-radius: 4px;
  margin-top: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  &:disabled {
    &:hover {
      cursor: default;
      background-color: $green10;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: #37afa1;
  }
}

.Selected {
  background-color: $green10;
  color: white;
  &:hover {
    background-color: #37afa1;
  }
}

.ItemsContainer {
  width: 420px;
}

// Help Item

.HelpItem {
  width: 420px;
  display: flex;
  flex-direction: row;
  padding: 16px 0px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.HelpItemLeft {
  display: flex;
}

.ItemTitle {
  font-size: 14px;
  margin-bottom: 6px;
}

.ItemDescription {
  font-size: 14px;
  color: #888888;
}

.Chevron {
  width: 10px;
  height: 10px;
  transform: rotate(90deg);
  fill: #888888;
}

.HelpItem:hover .Chevron {
  fill: #000000;
}

.HelpIconContainer {
  width: 28px;
}

.HelpItemIcon {
  width: 18px;
  height: 18px;
  fill: #37afa1;
}
