.SaveState {
  width: 64px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #c7c7c7;
  margin-right: 16px;
}

.Animation {
  -webkit-animation: FadeAnimation 0.2s ease-in 0.1s forwards;
  -moz-animation: FadeAnimation 0.2s ease-in 0.1s forwards;
  animation: FadeAnimation 0.2s ease-in 0.1s forwards;
}

@-webkit-keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-moz-keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@-o-keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
