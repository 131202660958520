@import './../../../scss/colours.module.scss';
.Modal {
  width: 500px;
  height: 235px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(82, 61, 61, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Header {
  display: flex;
  width: 440px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
}

.ModalTitle {
  color: #888888;
}

.Close {
  width: 10px;
  height: 10px;
  fill: #6b6b6b;
  cursor: pointer;
}

.Hr {
  border: 1px solid #f9f9f9;
  margin: 0px;
  width: 99.5%;
}

.FormInput {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 444px;
}

.ButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 14px;
}

.Button {
  height: 44px;
  width: 450px;
  background-color: $green10;
  outline: none;
  border: none;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
}

.Disabled {
  color: #d6d6d6;
  background-color: #f2f2f2;
  cursor: default;
}
