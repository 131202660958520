@import './../../scss/colours.module.scss';

.Success {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  background-color: $green10;
  color: white;
}

.Image {
  width: 240px;
}

.Auto {
  font-size: 24px;
  margin-top: 40px;
}

.Back {
  text-decoration: underline;
  font-size: 18px;
  margin-top: 40px;
  cursor: pointer;
}
