@import './../../scss/animations.module.scss';

$hover-color: #b3c9c5;

.Wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      174.08deg,
      #edfaf9 0.62%,
      rgba(242, 251, 251, 0.713542) 5.27%,
      rgba(255, 255, 255, 0) 109.15%
    ),
    linear-gradient(
      287.64deg,
      #f6f1f7 -5.61%,
      rgba(255, 254, 255, 0.0552304) 93.5%,
      rgba(255, 255, 255, 0) 93.5%
    ),
    linear-gradient(134.53deg, #ffffff 9.31%, rgba(255, 255, 255, 0) 110.91%);
}

.Container {
  display: flex;
  flex-direction: row;
  max-height: 680px;
  -webkit-animation: $fadeIn 1s;
  -moz-animation: $fadeIn 1s;
  animation: $fadeIn 1s;
}

.CommandsContainer {
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-right: 100px;
}

.PageIntroduction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.Greetings {
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  color: #000000;
  width: 274px;
  margin-top: 0px;
  margin-bottom: 48px;
  margin-left: -2px;
}

.Description {
  font-weight: 500;
  font-size: 16px;
  color: #354240;
}

.Separator {
  width: 100%;
  border: 2px solid #afb8b7;
}

.Commands {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.Command {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  cursor: pointer;
}

.Command:hover .Key {
  border: 2px solid $hover-color;
}

.Key {
  width: 40px;
  height: 40px;
  border: 2px solid #afb8b7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #354240;
}

.KeyTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #354240;
  margin-left: 18px;
  text-transform: uppercase;
}

.Command:hover .KeyTitle {
  color: $hover-color;
}

.Overlay {
  background-color: rgba(32, 16, 16, 0.6);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AnnouncementContainer {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.Announcement {
  height: 50px;
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: white;
  background-color: #342b9e;
  font-weight: 500;
}

.Link {
  color: white;
  margin-left: 4px;
}

.NewKey {
  border: 2px solid #c24eb8;
  color: #c24eb8;
}

.NewKeyTitle {
  color: #c24eb8;
}
