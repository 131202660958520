.Count {
  color: #c7c7c7;
  font-size: 14px;
  margin-right: 16px;
  cursor: pointer;
  border: 1px solid #c7c7c7;
  width: 80px;
  height: 22px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  &:hover {
    border: 1px solid #342b9e;
    box-shadow: 0px 2px 10px rgba(170, 140, 218, 0.24);
  }
}

.ActiveCount {
  border: 1px solid #342b9e;
  box-shadow: 0px 2px 10px rgba(170, 140, 218, 0.24);
}

.LanguageDropdown {
  color: #303030;
  font-size: 14px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 150px;
  overflow-y: auto;
  z-index: 2500;
  border-radius: 8px;
}

.Item {
  width: 100%;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  height: 20px;
  cursor: pointer;
  &:hover {
    background-color: #e2e0f7;
    color: #342b9e;
  }
}

.SelectedItem {
  background-color: #e2e0f7;
  color: #342b9e;
}

.Chevron {
  transform: rotate(180deg);
  width: 10px;
  height: 10px;
  fill: #c7c7c7;
  margin-left: 8px;
}

.OpenChevron {
  transform: rotate(0deg);
}

.Text {
  width: 120px;
  text-transform: capitalize;
}
