@import './../../scss/animations.module.scss';

.Pulse {
  background: #342b9e;
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;
  box-shadow: 0 0 0 0 #342b9e;
  transform: scale(1);
  -webkit-animation: $pulse 2s infinite;
  -moz-animation: $pulse 2s infinite;
  animation: $pulse 2s infinite;
  position: absolute;
  cursor: pointer;
  z-index: 8;
  &:hover {
    animation: none;
  }
}
