.ProjectItem {
  width: 212px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 18px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 18px;
  border: 1px solid #edefef;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  &:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  }
}

.Top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Emails {
  display: flex;
  flex-direction: row;
}

.Email {
  background-color: #a051b8;
  color: #ffffff;
  width: 20px;
  height: 20px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  margin-right: -2px;
  margin-left: -2px;
}

.Bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Info {
  display: flex;
  flex-direction: column;
}

.Name {
  color: #2a3d3a;
  font-size: 12px;
  font-weight: bold;
}

.FileCount {
  color: #98acb8;
  font-size: 12px;
  margin-top: 8px;
}

.Ellipsis {
  fill: #98acb8;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.FolderIcon {
  width: 24px;
  height: 24px;
}

.IconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  &:hover {
    background-color: #edefef;
  }
}

.Dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 160px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  gap: 4px;
}

.DropdownItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 16px;
  width: 128px;
  &:hover {
    background-color: #edefef;
  }
}

.TrashIcon {
  fill: #d93f1c;
  width: 16px;
  height: 16px;
}

.DrawIcon {
  width: 15px;
  height: 15px;
}

.ItemText {
  margin-left: 8px;
  font-size: 14px;
}
