@import './../../../scss/colours.module.scss';

.Generate {
  width: 152px;
  height: 40px;
  background: $green10;
  border-radius: 6px;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background: #37afa1;
  }
  &:disabled {
    cursor: default;
    &:hover {
      background: $green10;
    }
  }
}
