@import './../../scss/colours.module.scss';

.ResetPasswordPage {
  width: 480px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 600px) {
    width: auto;
    box-shadow: none;
  }
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin-bottom: 56px;
}

.FormInput {
  width: 356px;
  height: 44px;
}

.SendLink {
  width: 356px;
  height: 44px;
  background: $green10;
  border-radius: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 24px;
}

.RecoveryLink {
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}

.Info {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-top: 40px;
  width: 350px;
}
