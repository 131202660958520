@import './../../scss/colours.module.scss';

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.Navbar {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
}

.BrandName {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-left: 20px;
  margin-top: 15px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    margin: auto;
    width: auto;
  }
}

.RightHalf {
  display: flex;
  flex-grow: 100;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
