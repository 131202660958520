@import './../../../scss/colours.module.scss';

.TextArea {
  width: 100%;
  margin-bottom: -16px;
}

.Area {
  height: 150px;
}

.Error {
  color: #eb5757;
  text-align: center;
  margin-bottom: 24px;
}

.ReplaceButton {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $main-color;
  outline: none;
  border: none;
  background-color: $background-color;
  cursor: pointer;
  margin-left: 16px;
}
