@import './../../../scss/colours.module.scss';

.WelcomeModal {
  width: 400px;
  height: 440px;
  background: linear-gradient(
      10.13deg,
      #edfaf9 -10.82%,
      rgba(255, 255, 255, 0) 118.99%
    ),
    linear-gradient(
      231.51deg,
      #f6f1f7 4.51%,
      rgba(255, 255, 255, 0) 111.94%,
      rgba(255, 254, 255, 0.0552304) 111.94%
    ),
    linear-gradient(100.24deg, #ffffff 49.75%, rgba(255, 255, 255, 0) 101.35%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.Icon {
  width: 80px;
  margin-bottom: 40px;
}

.TitleContainer {
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
}

.SubtitleContainer {
  color: #858585;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 16px;
}

.Description {
  color: black;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  width: 350px;
  margin-bottom: 32px;
}

.ButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.Button {
  outline: none;
  border: none;
  cursor: pointer;
}

.TourButton {
  @extend .Button;
  background-color: $green10;
  height: 38px;
  border-radius: 4px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 16px;
  font-weight: 600;
  text-transform: capitalize;
  &:hover {
    background: #37afa1;
  }
}

.SkipButton {
  @extend .Button;
  background-color: transparent;
}
