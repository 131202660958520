.Modal {
  min-height: 420px;
  width: 500px;
  background-color: white;
  border-radius: 8px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 420px;
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
}

.Title {
  color: #888888;
}

.Close {
  width: 10px;
  height: 10px;
  fill: #6b6b6b;
  cursor: pointer;
}

.Hr {
  border: 1px solid #f9f9f9;
  margin: 0px;
  width: 99.5%;
}

.Subtitle {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin-top: 20px;
}

.FormInput {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 425px;
}

.Projectsubtitle {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.SelectedProjects {
  margin-top: 16px;
  width: 409px;
  border: none;
  outline: none;
  font-size: 14px;
  color: black;
  font-weight: normal;
  background-color: #f3f3f3;
  border-radius: 6px;
  min-height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 2px 8px;
}

.SelectedProject {
  padding: 4px 6px;
  height: 24px;
  background-color: #dfdafc;
  color: #342b9e;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.ProjectsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
  width: 425px;
}

.Project {
  font-weight: normal;
  font-size: 14px;
  padding: 6px 8px;
  cursor: pointer;
  background-color: #f3f3f3;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  &:hover {
    background-color: #ebe9e9;
  }
}

.Selected {
  cursor: default;
  color: #f9f9f9;
  &:hover {
    background-color: #f3f3f3;
  }
}

.SelectedCloseIcon {
  height: 10px;
  width: 10px;
  margin-left: 8px;
  cursor: pointer;
  fill: #342b9e;
}

.AddMember {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 85%;
  height: 44px;
  background: #2a9d8f;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-style: normal;
  // font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    color: #d6d6d6;
    background-color: #f2f2f2;
    &:hover {
      cursor: default;
      background-color: #f2f2f2;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: #37afa1;
  }
}

.Description {
  font-size: 12px;
  font-weight: normal;
  margin-top: 8px;
}

.ErrorMessage {
  font-size: 12px;
  color: #d93f1c;
  margin-top: 8px;
}
