@import './../../scss/colours.module.scss';

.SettingsPage {
  margin-top: 52px;
}

.Header {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 2px solid #f5f5f5;
  margin-top: 36px;
}

.Title {
  font-size: 18px;
  color: #2a3d3a;
}

.SettingsTabs {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}

.SettingsTab {
  height: 54px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 32px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: #37afa1;
  }
}

.SelectedTab {
  border-bottom: 2px solid #37afa1;
  color: #37afa1;
}

.Form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

.TitleContainer {
  width: 88px;
  margin-right: 40px;
}

.FormTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.FormInput {
  width: 350px;
}

.Reset {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #000000;
  cursor: pointer;
}

.GeneralButton {
  height: 36px;
  width: 160px;
  background: linear-gradient(
    90deg,
    #2a9d8f 59.45%,
    rgba(42, 157, 143, 0) 202.09%
  );
  outline: none;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 64px;
}

.Disabled {
  color: #ffffff;
  background: #f2f2f2;
  cursor: default;
}

.Billing {
  margin-top: 40px;
}

.Currentplan {
  color: #000000;
  display: flex;
}

.HR {
  border: 1px solid #f1f1f1;
  margin-top: 24px;
  margin-bottom: 24px;
}

.Subheader {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 16px;
}

.Text {
  margin-left: 2px;
  margin-right: 2px;
  font-size: 14px;
}

.ExpiryContainer {
  margin-top: 8px;
  color: #000000;
  display: flex;
  font-size: 14px;
}

.Date {
  margin-left: 2px;
  font-weight: bold;
}

.Plan {
  font-weight: bold;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 14px;
}

.Manage {
  outline: none;
  border: none;
  text-decoration: underline;
  background: none;
  font-size: 14px;
  margin-top: 16px;
  margin-left: 3px;
  cursor: pointer;
  padding: 0px;
}

.Prices {
  display: flex;
}

.Button {
  background: linear-gradient(
    90deg,
    #2a9d8f 59.45%,
    rgba(42, 157, 143, 0) 202.09%
  );
  border-radius: 4px;
  width: 160px;
  height: 36px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 24px;
  &:hover {
    background: #37afa1;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.UnlimitedSection {
  margin-top: 40px;
}

.Discount {
  color: #d93f1c;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 24px;
}

.TeamHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.ManageTeam {
  font-size: 14px;
  font-weight: bold;
}

.AddTeam {
  width: 120px;
  height: 28px;
  background: #2a9d8f;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 12px;
  &:hover {
    background: #37afa1;
  }
}

.FolderTitle {
  font-size: 14px;
  margin-bottom: 14px;
  font-weight: bold;
  color: #818181;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  max-width: 100%;
  background-color: #f3f3f3;

  position: relative;
}

.FolderContainer {
  max-width: 100%;
  margin-top: 24px;
  border: 1px solid #f3f3f3;
  border-radius: 6px;
  overflow: hidden;
}

.Members {
  width: 100%;
  margin-bottom: 12px;
}

.Member {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  font-size: 14px;
  color: #818181;
  margin-left: 24px;
  margin-right: 24px;
}

.Remove {
  color: #d93f1c;
  cursor: pointer;
}

.Prices {
  display: flex;
}

.Card {
  width: 200px;
}

.Deal {
  color: red;
}

.AppsumoCode {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.ASButton {
  width: 120px;
  height: 36px;
  background: #2a9d8f;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
  margin-top: 18px;
  &:hover {
    background: #37afa1;
  }
}

.CodeError {
  color: red;
  font-size: 14px;
  margin-bottom: 14px;
}

.AsFeatures {
  margin-top: 16px;
}
